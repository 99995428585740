import React from "react";
import { Container, Box, Text, Image } from "theme-ui";
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"
import { Button } from "../../components/button";
import data from "../../cms/content/buyPack.json";
/*import pack1 from "assets/buyPack/pack1.png";
import pack2 from "assets/buyPack/pack2.png";
import pack3 from "assets/buyPack/pack3.png";
import pack4 from "assets/buyPack/pack4.png";*/
import bg1 from "assets/buyPack/bg1.svg";

// const packs = { pack1, pack2, pack3, pack4 };

export default function BuyPack() {
    const intl = useIntl();
    
    return (
        <Box as="section" id="buyPack" sx={styles.buyPack}>
            <Container sx={styles.container}>
                <Box as="div">
                    <Text as="h3" sx={styles.subTitle}>
                        <FormattedMessage id="buy.subtitle" />
                    </Text>
                    <Text as="h2" sx={styles.title}>
                        <FormattedMessage id="buy.title" />
                    </Text>
                </Box>
                <Box as="div" sx={styles.packsWrapper}>
                    { data.map((item, index) => (         
                        <Box as="div" sx={styles.pack} key={index}>
                            <Box as="div" sx={styles.colImage}>
                                <Image src={`/img/how-to-play/${item.image}`} alt={item[intl.locale].name} sx={styles.packImage} />
                            </Box>
                            <Box as="div" sx={styles.colText} className="buyPackButton">                                
                                <Text sx={styles.packName}>
                                    {/*{item[intl.locale].name}*/}
                                </Text>
                                <Text sx={styles.packBody}>
                                    {/*{item[intl.locale].body}*/}
                                </Text>
                                <Box as="div" sx={styles.buttonCnt}>
                                <Button 
                                    label="BUY NOW" 
                                    target="_blank" 
                                    to={item.link} 
                                    variant="lg"
                                />
                                </Box>
                            </Box>
                        </Box>
                    )) }
                </Box>
                <Box as="div" style={{ display: "flex", justifyContent: "center" }}>
                    <Box as="div" sx={styles.footerBackground}>
                        <Text as="div" sx={styles.footerText}>
                            <FormattedMessage id="buy.cta" />
                        </Text>
                        <Box as="a" href="https://worldsuperleague.noku.io/app/author/635fe81348a6897f90f395dc" target="_blank" sx={styles.footerButton}>
                            <Text as="span" sx={styles.textButton}>
                                <FormattedMessage id="buy.button" />
                            </Text>
                        </Box>
                    </Box>
                </Box>
            </Container>
            <Image src={bg1} sx={styles.bg1} alt="g" />
        </Box>
    );
}


const styles = {
    buyPack: {
        backgroundColor: "#5E2FE3",
        position: "relative",
        zIndex: 10,
        pt: 40
    },
    container: { 
        pb: [null, "46px", "92px"],
    },
    pack: {
        mb: ["29px", "37px", "69px"],
        width: ["100%", "50%", "25%"],
        px: ["20px"],
        display: "flex",
        flexDirection: ["column", "column", "column"],     
        justifyContent: "center",
        alignItems: "center"
    },
    colImage: {
    },
    colText: {
    },
    packImage: {
        display: "flex",
    },
    packName: {
        display: "block",
        color: "#fff",        
        fontFamily: "Lexend Zetta",
        fontStyle: "normal",
        fontWeight: "800",
        fontSize: ["12px", "16px", "24px"],
        lineHeight: [null, "60px", "60px"],
        textAlign: ["left", null, "center"],
        letterSpacing: "-3px",
        justifyContent: "center",
    },
    packBody: {
        mb: ["17px", "12px", "15px"],
        display: "block",
        color: "#fff",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: ["10px", "14px", "16px"],
        lineHeight: ["14px", "18px", "24px"],
        textAlign: ["left", null, "center"],
        letterSpacing: "1px",
    },
    packButton: {
        border: "2px solid #fff",
        borderRadius: "3px",
        width: ["110px", "110px", "168px"],
        height: ["30px", "30px", "50px"],
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        textDecoration: "none",
    },
    buttonCnt: { 
        display: "flex", 
        justifyContent: ["center", "center", "center"],
    },
    textPackButton: {
        color: "#fff",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: ["12px", "12px", "18px"],
        lineHeight: "22px",
        textAlign: "center",
        letterSpacing: "0.15px",
    },
    footerButton: {
        display: "flex",
        justifyContent: "center",
        border: ["1px solid #5E2FE3", "2px solid #5E2FE3", "2px solid #5E2FE3"],
        borderRadius: "3px",
        mt: [20, 0],
        px: [16],
        py: [10],
        alignItems: "center",
        cursor: "pointer",
        textDecoration: "none",
    },
    textButton: {
        color: "#5E2FE3",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: ["12px", "12px", "18px"],
        lineHeight: "22px",
        textAlign: "center",
        letterSpacing: "0.15px",
        
    },
    footerText: {
        color: "#5E2FE3",
        fontFamily: "Lexend Zetta",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: ["12px", "16px", "25px"],
        lineHeight: ["17px", "32px", "32px"],
        letterSpacing: ["-2.6px", "-3.2px", "-5.5px"],
    },
    footerBackground: {
        py: ["9px", "20px", "20px"],
        mt: ["8px", "0px", "0px"],
        px: ["14px", "39px", "39px"],
        mb: ["56px", "0px", "0px"],
        backgroundColor: "#62FFFF",
        width: ["80%", "90%", "100%"],
        display: "flex",
        flexDirection: ["column", "row", "row"],
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: "3px",
        boxShadow: "#FFE162 -10px 10px",
    },
    subTitle: {
        mb: ["3px", "1px", "9px"],
        color: "#FFE162",
        fontFamily: "Barlow Condensed",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: ["16px", "20px", "30px"],
        lineHeight: ["19px", "24px", "36px"],
        textAlign: "center",
        letterSpacing: "0.15px",
    },
    title: {
        mb: ["26px", "29px", "50px"],
        color: "#62FFFF",
        fontFamily: "Lexend Zetta",
        fontStyle: "normal",
        fontWeight: "800",
        fontSize: ["30px", "40px", "60px"],
        lineHeight: ["32px", "39px", "60px"],
        textAlign: "center",
        letterSpacing: ["-4px", "-6.85px", "-10.85px"],
        px: [32, 0]
    },
    packsWrapper: {
        display: "flex",
        justifyContent: ["center", "space-around", "space-between"],
        flexWrap: "wrap",
        flexDirection: "row",
        px: [16, 16, 0]
    },
    bg1: {
        position: "absolute",
        bottom: -11,
        left: 0
    }
};
