import React from "react";
import { Box, Container, Image } from "theme-ui";
import { Button } from "../../components/button";
import bannerDesk from "assets/imageBanner/wsl-background.png";
import cards from "assets/imageBanner/cards-min.png";
import cardsMobile from "assets/imageBanner/cards-mobile-min.png";

const ImageBanner = () => {
    return (
        <Box as="section" id="home" sx={styles.banner}>
            <Container sx={styles.container}>
                <Box as="div" sx={styles.textAndImageContainer}>
                    <Box as="div" sx={styles.bannerColumnText}>
                        <Box as="div" sx={styles.subTextOnBanner}>
                            WORLD SUPER LEAGUE
                        </Box>
                        <Box as="div" sx={styles.textOnBanner}>
                            PLAY YOUR FANTASY FOOTBALL GAME
                        </Box>
                        <Button color="yellow" variant="lg" label="PLAY NOW" to="https://worldsuperleague.noku.io" />
                    </Box>
                    <Box as="div" sx={styles.bannerColumnImage}> 
                        <Box as="div" sx={styles.containerImage}>
                            <Image sx={styles.cardsMobile} src={cardsMobile} alt="WSL cards" />  
                            <Image sx={styles.cards} src={cards} alt="WSL cards" />    
                        </Box>     
                    </Box>             
                </Box>
            </Container>
        </Box>
    );
};

export default ImageBanner;

const styles = {
    bannerColumnText: {
        width: ["78%", "78%", "50%", "50%"],
        pl: ["29px", "29px", "40px", "60px"],
        pt: ["30px", "30px", "0px", "0px"],                
        "@media screen and (min-width: 520px)": {
            maxWidth: "50%",
        },
    },
    bannerColumnImage: {
        width: "50%",
    },
    textAndImageContainer: {
        display: "flex", 
        flexDirection: ["column", "column", "row", "row"], 
        alignItems: ["flex", "flex-start", "center", "center"],         
    },
    banner: {
        pt: "80px",
        backgroundImage:  `url('${bannerDesk}')`,
        backgroundSize: 'cover',
        "@media screen and (max-width: 1023px)": {
            height: "calc(100vh - 40px)"
        },
        backgroundPosition: "bottom",
        position: "relative",
        overflow: "hidden"
    },
    imageBanner: {
        width: "100%",
        height: [null, "486px", "100%"],
        objectFit: "cover",
        position: "relative",
    },
    cards: {
        height: "84vh",
        maxWidth: "999em",
        display: ["none", "none", "block"],
        pointerEvents: "none"
    },
    cardsMobile: {
        display: ["block", "block", "none"],
        position: "absolute",
        bottom: 0,
        right: 0,
        pointerEvents: "none",
        "@media screen and (max-width: 480px)": {
            height: "75vh",
            maxWidth: "none"
        },
    },
    subTextOnBanner: {        
        color: "#FFE162",
        fontFamily: "Barlow Condensed",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: ["16px", "20px", "30px", "30px"],
        lineHeight: 1,
        letterSpacing: "0.15px",
        userSelect: "none"
    },
    textOnBanner: {  
        mb: ["21px", "21px", "24px", "45px"], 
        ml: ["-5px", "-10px", "-10px"],     
        color: "#fff",
        fontFamily: "Lexend Zetta",
        fontStyle: "normal",
        fontWeight: "800",
        fontSize: ["35px", "60px", "60px", "80px"],        
        lineHeight: .9,
        letterSpacing: ["-5px", "-9.85px", "-12.85px"],
        width: [null, "80%", "70%"],   
        userSelect: "none"
    },
    borderButton: {        
        flexDirection: "column",
        display: "flex",
        justifyContent: "center",
        height: ["30px", "30px", "35px", "50px"],
        width: ["90px", "90px", "118px", "168px"],
        border: ["1px solid #fff", "1px solid #fff", "2px solid #fff", "2px solid #fff"],
        borderRadius: "3px",
        cursor: "pointer",
    },    
    button: {
        color: "#fff",
        backgroundColor: "transparent",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: ["12px", "12px", "12px", "18px"],
        lineHeight: "22px",
        textAlign: "center",
        letterSpacing: "0.15px",
        textDecoration: "none",
        display: "inline-block",
    },
};
