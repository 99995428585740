import React from "react";
import { Box, Text, Image, Container } from "theme-ui";
import { useIntl } from "gatsby-plugin-intl";
import data from "../../cms/content/roadMap.json";
import strips from "assets/roadmap/strips.svg";
import dots from "assets/roadmap/dots.svg";
import dots2 from "assets/roadmap/dots2.svg";

export default function RoadMap() {
    const intl = useIntl();

    return (
        <Box as="section" id="roadMap" sx={styles.roadMap}>
            <Container>
                <Box as="div">
                    <Text as="div" sx={styles.subTitle}>
                        ROAD TO WSL
                    </Text>
                    <Text as="div" sx={styles.title}>
                        ROADMAP
                    </Text>
                </Box>
                <Box as="div" sx={styles.infoWrapper}>
                    { data.map((item, index) => (       
                        <Box as="div" key={index} sx={styles.infoBoxCnt}>
                            <Box
                                sx={styles.infoBox} 
                                style={{ 
                                    backgroundColor: item.color,
                                    boxShadow: `${item.shadow} -10px 10px`
                                }}>
                                <Text sx={styles.infoTitle}>
                                    {item[intl.locale].title}
                                </Text>
                                <Text sx={styles.infoSubTitle}>
                                    {item[intl.locale].subTitle}
                                </Text>
                                <Text sx={styles.infoBody}>
                                    {item[intl.locale].body}
                                </Text>                                
                            </Box>
                            <Image src={strips} sx={{...styles.strips, ...styles["strips" + (index % 2)]}} alt="g" />
                        </Box>
                    )) }
                </Box>
            </Container>
            {/* 
            <Box as="div" style={{overflow: "hidden"}}>
                
                <Box as="div" sx={styles.row}>  
                    <Box as="span" sx={styles.rowText}>
                        AMBASSADOR
                    </Box>
                    <Image
                        sx={styles.icon}
                        src={logo}
                        alt="WSL logo"
                    />
                    <Box as="span" sx={styles.rowText}>
                        AMBASSADOR
                    </Box>
                    <Image
                        sx={styles.icon}
                        src={logo}
                        alt="WSL logo"
                    />
                    <Box as="span" sx={styles.rowText}>
                        AMBASSADOR
                    </Box>
                    <Image
                        sx={styles.icon}
                        src={logo}
                        alt="WSL logo"
                    />
                    <Box as="span" sx={styles.rowText}>
                        AMBASSADOR
                    </Box>
                    <Image
                        sx={styles.icon}
                        src={logo}
                        alt="WSL logo"
                    />
                    <Box as="span" sx={styles.rowText}>
                        AMBASSADOR
                    </Box>
                    <Image
                        sx={styles.icon}
                        src={logo}
                        alt="WSL logo"
                    />
                    <Box as="span" sx={styles.rowText}>
                        AMBASSADOR
                    </Box>
                    <Image
                        sx={styles.icon}
                        src={logo}
                        alt="WSL logo"
                    />
                    <Box as="span" sx={styles.rowText}>
                        AMBASSADOR
                    </Box>
                    <Image
                        sx={styles.icon}
                        src={logo}
                        alt="WSL logo"
                    />
                    <Box as="span" sx={styles.rowText}>
                        AMBASSADOR
                    </Box>
                    <Image
                        sx={styles.icon}
                        src={logo}
                        alt="WSL logo"
                    />
                </Box>
            </Box>
            */}
        </Box>
    );
}


const styles = {
    roadMap: { 
        backgroundColor: "#1C1F26",
        position: "relative",
        overflow: "hidden",
        zIndex: 1,
        pb: 60,
        ":before": {
            content: '""',
            position: "absolute",
            background: `url(${dots}) repeat-y top left`,
            top: 0,
            height: "100%",
            width: 480,
            left: [-360, -200]
        },
        ":after": {
            content: '""',
            position: "absolute",
            background: `url(${dots2}) repeat-y top left`,
            top: 0,
            height: "100%",
            width: 480,
            right: [-360, -200]
        }
    },    
    icon: {
        width: "43px", 
        mx: "13px",
    },
    row: {
        marginTop: [null, "59px", "78px"],
        display: "flex",
        width: [null, "183%", "110%"],
        ml: [null, "-137%", "-55%"],
        height: "60px",
        backgroundColor: "#fff",
        justifyContent: "center",
        "@media screen and (max-width: 1023px)": {
            display: "none",
        },
    },
    rowText: {
        px: "13px",
        color: "#000",
        fontFamily: "Lexend Zetta",
        fontStyle: "normal",
        fontWeight: "800",
        fontSize: "16px",
        lineHeight: "88px",
        letterSpacing: "-3px",
        alignSelf: "center",
    },
    infoBody: {
        color: "#fff",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: ["9px", "9px", "16px"],
        lineHeight: ["12px", "12px", "24px"],
        letterSpacing: "1px",
        display: "block",
    },
    infoSubTitle: {
        mb: ["14px", "22px"],
        color: "#fff",
        fontFamily: "Lexend Zetta",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: ["14px", "14px", "20px"],
        lineHeight: [1.1],
        letterSpacing: ["-2.5px", "-2.5px", "-4.5px"],
        display: "block",
    },
    infoTitle: {
        color: "#fff",
        fontFamily: "Lexend Zetta",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: ["14px", "14px", "25px"],
        lineHeight: [null, null, "32px"],
        letterSpacing: ["-2.5px", "-2.5px", "-4.5px"],
        textTransform: "uppercase"
    },
    infoBoxCnt: {
        position: "relative",        
        width: ["100%", "54%"],
        px: [32, 0]
    },
    infoBox: {
        mb: ["38px", "39px", "65px"],
        pt: ["8px", "20px", "28px"],
        pb: ["18px", "20px", "28px"],
        px: ["17px", "19px", "36px"],
        zIndex: 10,
        position: "relative",
        borderRadius: "3px",
    },
    strips: {
        position: "absolute",
        top: "calc(50% - 45px)",
        right: -120,
        pointerEvents: "none",
        zIndex: 0,
        "@media screen and (max-width: 800px)": {
            display: "none",
        },
    },
    strips1: {
        right: "auto",
        left: -120,
    },
    infoWrapper: {
        pb: ["54px", "0px", "0px"],
        display: "flex",
        alignContent: "center",
        flexWrap: "wrap",
        flexDirection: "column",
    },
    subTitle: {
        pt: ["54px", "61px", "95px"],
        color: "#62FFFF",
        fontFamily: "Barlow Condensed",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: ["16px", "20px", "30px"],
        lineHeight: ["19px", "24px", "36px"],
        textAlign: "center",
        letterSpacing: "0.15px",
    },
    title: {
        mt: ["3px", "1px", "9px"],
        mb: ["15px", "28px", "53px"],
        color: "#FFE162",
        fontFamily: "Lexend Zetta",
        fontStyle: "normal",
        fontWeight: "800",
        fontSize: ["30px", "40px", "60px"],
        lineHeight: ["32px", "39px", "60px"],
        textAlign: "center",
        letterSpacing: ["-4px", "-6.85px", "-10.85px"],
    },
};
