import React from "react";
import { Container, Box, Text, Image } from "theme-ui";
import { useIntl, FormattedHTMLMessage } from "gatsby-plugin-intl";
import IsVisible from 'react-is-visible';
import data from "../../cms/content/cardsGame.json";
import bgLine1 from "assets/cardsGame/bg-line-1.png";
import bgLine2 from "assets/cardsGame/bg-line-2.png";
import wsl from "assets/cardsGame/wsl.svg";
import whiteCard from "assets/cardsGame/white-cards.png";
import blackCard from "assets/cardsGame/black-cards.png";
import greenCard from "assets/cardsGame/green-cards.png";
import goldCard from "assets/cardsGame/gold-cards.png";
import blueCard from "assets/cardsGame/blue-cards.png";
import coachCard from "assets/cardsGame/coach-cards.png";
import moduleCard from "assets/cardsGame/module-cards.png";
import purpleCard from "assets/cardsGame/purple-cards.png";
import bonusCard from "assets/cardsGame/bonus-cards.png";

const cards = { whiteCard, blueCard, blackCard, purpleCard, greenCard, goldCard, coachCard, moduleCard, bonusCard };

export default function CardsGame() {
    const intl = useIntl();
    const cardsRefs = data.map(() => React.createRef() );    

    return (
        <Box as="section" id="cardsGame" sx={styles.section}>
            <Image alt="g" src={bgLine1} sx={styles.bgLine1} />
            <Image alt="g" src={bgLine2} sx={styles.bgLine2} />
            <Box sx={styles.textStrip}>
                <Container>
                    <Box as="div" sx={styles.containerText}>
                        <Text as="h2" sx={styles.title}>
                            <FormattedHTMLMessage id="cards.title" />
                        </Text>
                        <Text as="div" sx={styles.subTitle}>
                            <FormattedHTMLMessage id="cards.text" />
                        </Text>
                    </Box>            
                </Container>
            </Box>
            <Container sx={styles.container}>                                
                <Box as="div" sx={styles.cardWrapper}>
                    { data.map((item, index) => (         
                        <Box as="div" sx={styles.card} key={index}>
                            <div className="scramble-cnt">
                                <IsVisible once>
                                {(isVisible) => 
                                    <>                                    
                                    <Image 
                                        ref={cardsRefs[index]}
                                        src={cards[item.image]} 
                                        alt={item.image} 
                                        className={isVisible ? 'enter-scramble in-view scramble-clone' : 'enter-effect scramble-clone'}
                                        sx={styles.cardImage} />
                                    <Image 
                                        ref={cardsRefs[index]}
                                        src={cards[item.image]} 
                                        alt={item.image} 
                                        className={isVisible ? 'enter-scramble in-view scramble-clone' : 'enter-effect scramble-clone'}
                                        sx={styles.cardImage} />
                                    <Image 
                                        ref={cardsRefs[index]}
                                        src={cards[item.image]} 
                                        alt={item.image} 
                                        className={isVisible ? 'enter-scramble in-view' : 'enter-effect'}
                                        sx={styles.cardImage} />
                                    </>
                                }
                                </IsVisible>
                            </div>
                            <Box as="div" sx={styles.cardText}>
                                <Text sx={styles.cardType}>
                                    {item[intl.locale].type}
                                </Text>
                                <Text sx={styles.cardBody}>
                                    {item[intl.locale].body}
                                </Text>
                            </Box>
                        </Box>
                    )) }
                </Box>
            </Container>
        </Box>
    );
}

const styles = {
    container: {
        position: "relative",
        zIndex: 10,
        mt: -140
    },
    textStrip: {
        background: `#000  url('${wsl}') no-repeat bottom right`,
        backgroundSize: "50%",
        pb: 200
    },
    bgLine1: {
        position: "absolute",
        top: [38, 0],
        left: 0,
        zIndex: 2,
        pointerEvents: "none",
        width: [12, "auto"]
    },
    bgLine2: {
        position: "absolute",
        top: ["40%", "40%", "40%"],
        right: 0,
        zIndex: 2,
        pointerEvents: "none",
        width: [12, "auto"]
    },
    swlImage: {
        position: "absolute",
        top:0,
        left: 0,
        right: 0,
        zIndex: 1,
        width: "100%",
        pointerEvents: "none",
        display: ["none", "block"]
    },
    swlImageMobile: {
        display: ["block", "none"]
    },
    cardText: {
        "@media screen and (max-width: 1023px)": {
            display: "flex",
            justifyContent: "center",
        },
    },
    containerText: {
        pl: ["9%", "4%", "4%", "116px"],
    },
    section: {        
        width: "100%",
        position: "relative",
        
    },
    card: {
        mb: ["26px", "44px", "84px"],
        mx: "auto",
        maxWidth: "80%"        
    },
    cardImage: {
        mb: "10px",
        display: "flex",
        width: ["141px", "100%", "100%"],
        zIndex: 10,
        position: "relative",        
        "@media screen and (min-width: 600px) and (max-width: 1024px)": {
            width: "100%"
        },
    },
    cardType: {
        display: "block",
        textTransform: "uppercase",
        color: "#000",
        fontFamily: "Lexend Zetta",
        fontStyle: "normal",
        fontWeight: "800",
        fontSize: ["12px", "16px", "20px"],
        lineHeight: 1.2,
        my: [20],
        textAlign: "center",
        letterSpacing: ["-2px", "-3px", "-3px"],
        justifyContent: "flex-start",
        "@media screen and (max-width: 1023px)": {
            width: "97px",
        },
    },
    cardBody: {
        display: "block",
        color: "#333",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: [null, "14px", "20px"],
        lineHeight: [null, "20px", "32px"],
        textAlign: "center",
        letterSpacing: "1px",
        "@media screen and (max-width: 1023px)": {
            display: "none",
        },
    },
    subTitle: {
        pt: ["13px", "14px", "30px"],
        color: "#fff",
        fontFamily: "Lato",
        fontWeight: "500",
        fontStyle: "normal",
        fontSize: ["12px", "14px", "20px"],
        lineHeight: ["18px", "21px", "32px"],
        letterSpacing: "1px",
        width: ["100%", "80%", "80%", "50%"],
    },
    title: {
        pt: ["26px", "54px", "67px"],
        color: "#FF0089",
        fontFamily: "Lexend Zetta",
        fontStyle: "normal",
        fontWeight: "800",
        fontSize: ["30px", "40px", "60px"],
        lineHeight: 1.2,
        letterSpacing: ["-4px", "-6.85px", "-10.85px"],
    },
    cardWrapper: {
        display: "grid",
        gridTemplateColumns: ["repeat(2, 1fr)", "repeat(4, 1fr)"],
        justifyContent: "space-between",
        gridGap: "20px",
        mb: ["66px", "63px", "52px"],
        "@media screen and (max-width: 1023px)": {
            justifyContent: "center",
        },
    },
};
