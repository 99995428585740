import React from "react";
import { Container, Box, Text, Image, Paragraph } from "theme-ui";
import { useIntl, FormattedMessage } from "gatsby-plugin-intl";
/*import image1 from "assets/howToPlay/howToSetFormation.png";
import image2 from "assets/howToPlay/howToBuyCards.png";
import image3 from "assets/howToPlay/howToBuyBonusCards.png";*/

import banner from "assets/howToPlay/how-to-play.png";
import bannerMobile from "assets/howToPlay/how-to-play-mobile.png";
import { Button } from "../../components/button";

import triangle from "assets/howToPlay/triangle.svg";
import dots from "assets/howToPlay/dots.svg";
import stains from "assets/howToPlay/stains.png";
//import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

/*const images = { image1, image2, image3 }*/

export default function HowToPlay() {
    const intl = useIntl();
    return (
        <Box as="section" id="news" sx={styles.topBackground}>
            <Image className="gfx" src={triangle} alt="g" sx={styles.triangle} />
            <Image className="gfx" src={dots} alt="g" sx={styles.dots} />
            <Image className="gfx" src={triangle} alt="g" sx={styles.triangleBottom} />            
            <Container>
                <Text as="h3" sx={styles.subTitle}>
                    TUTORIAL
                </Text>
                <Text as="h2" sx={styles.title}>
                    <FormattedMessage id="how.title" />
                </Text>
                <Paragraph sx={styles.text}>
                    <FormattedMessage id="how.subtitle" />
                </Paragraph>
                <Box sx={styles.sliderContainer}>
                    <Image sx={styles.banner} src={banner} alt={intl.formatMessage({ id: "how.title" })} />
                    <Image sx={styles.bannerMobile} src={bannerMobile} alt={intl.formatMessage({ id: "how.title" })} />
                </Box>
                <Box as="div" sx={styles.buttonContainer}>
                    {
                        <Button 
                            label={intl.formatMessage({ id: "how.button" })} 
                            to={`/${intl.locale}/how-to-play`} 
                            variant="lg" />
                    }
                </Box>
            </Container>            
        </Box>
    );
}

const styles = {
    text: { 
        color: '#fff', 
        textAlign: 'center',
        fontSize: 22,
        mb: 40
    },
    carouselDurationContainer: {
        display: "flex", 
        alignItems: "center",
        position: "absolute",
        bottom: "4px"
    },
    carouselImage: {
        width: "100%",
        borderTopLeftRadius: "5px",
        borderTopRightRadius: "5px",
    },
    clock: {
        mr: "3px",
        width: ["10px", "10px", "16px"],
    },
    banner: {
        display: ['none', 'block']
    },
    bannerMobile: {
        display: ['block', 'none']
    },
    textSeparatorCarousel: {
        px: "5px",
        color: "#333",
        opacity: "0.4",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: ["8px", "8px", "12px"],
        lineHeight: "24px",
        letterSpacing: "0.15px",
    },
    buttonContainer: {
        display: "flex", 
        justifyContent: "center",
        mt: ["40px"],
        pb: ["79px", "43px", "68px"],
    },
    fromImageCarousel: {
        color: "#333",
        opacity: "0.4",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: ["8px", "8px", "12px"],
        lineHeight: "24px",
        letterSpacing: "0.15px",
    },
    durationImageCarousel: {
        color: "#333",
        opacity: "0.4",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: ["8px", "8px", "12px"],
        lineHeight: "24px",
        letterSpacing: "0.15px",
    },
    titleImageCarousel: {
        mb: [null, null, "12px"],
        display: "block",
        color: "#000",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: ["11px", "11px", "18px"],
        lineHeight: 1.1,
        letterSpacing: "0.15px",
    },
    carouselTextBox: {
        p: "10px",
        backgroundColor: "#fff",
        width: "100%",
        height: [null, "68px", "120px"],
        display: "flex",
        alignItems: "start",
        flexDirection: "column",
        borderBottomLeftRadius: "5px",
        borderBottomRightRadius: "5px",
        "@media (max-width: 1023px)": {
            height: "68px",
            width: "100%",
            maxWidth: "458px"
        }
    },
    boxCarouselImage: {
        px: ["10px", "13px", "21px"],
        position: "relative",
        "@media (max-width: 1023px)": {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
        }
    },
    carouselBox: {
        mb: ["48px", "27px", "48px"],
        display: "flex",
        justifyContent: "center",
        "@media (max-width: 1023px)": {
            width: "300%",
        }
    },
    comingSoon: {
        position: "absolute",
        top: 0,
        left: ["10px", "13px", "21px"],
        right: ["10px", "13px", "21px"],
        bottom: 0,
        zIndex: 100,
        background: "rgba(255, 255, 255, .5)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        transition: "all .5s",

        "&:hover": {
            background: "rgba(255, 255, 255, .9)",
        }
    },
    comingSoonText: {
        textAlign: "center",
        fontSize: [12, 14, 14, 20],
        mx: 20,
        fontWeight: "bold",
        border: "3px solid #333",
        display: "inline-block",
        px: 16
    },
    button: {
        display: "flex",
        justifyContent: "center",
        border: ["1px solid #fff", "2px solid #fff" ,"2px solid #fff"],
        borderRadius: "3px",
        width: ["160px", "192px", "285px"],
        height: ["30px", "35px", "50px"],
        alignItems: "center",
        cursor: "pointer",
        textDecoration: "none",
    },
    textButton: {
        color: "#fff",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: ["12px", "12px", "18px"],
        lineHeight: "22px",
        textAlign: "center",
        letterSpacing: "0.15px",
    },
    sliderContainer: {
        width: "calc(100% - 100px)",
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    },
    topBackground: {
        position: "relative",
        background: `#59B359 url(${stains}) no-repeat bottom`,
        backgroundSize: "100%",
        width: "100%",
        pt: [null, "47px", "85px"],
        ".slick-slider .slick-arrow": {
            width: ["40px"],
            height: ["40px"],
            opacity: "0.8",
            color: "#000",
            borderRadius: "50%",
            zIndex: "1",
        },
        ".slick-slider .slick-prev": {
            left: "-50px"
        },
        ".slick-slider .slick-next": {
            right: "-50px"
        }
    },
    subTitle: {
        pt: ["42px", "0px", "0px"],
        color: "#FFE162",
        fontFamily: "Barlow Condensed",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: ["16px", "20px", "30px"],
        lineHeight: ["19px", "24px", "36px"],
        textAlign: "center",
        letterSpacing: "0.15px",
    },
    title: {
        mt: ["3px", "1px", "9px"],
        mb: ["15px", "33px", "60px"],
        color: "#fff",
        fontFamily: "Lexend Zetta",
        fontStyle: "normal",
        fontWeight: "800",
        fontSize: ["30px", "40px", "60px"],
        lineHeight: ["32px", "39px", "60px"],
        textAlign: "center",
        letterSpacing: ["-4px", "-6.85px", "-10.85px"],
    },
    meta: {
        position: "absolute",
        bottom: 4
    },
    triangle: {
        position: "absolute",
        top: -281,
        right: 0,        
        "@media (max-width: 1660px)": {
            height: 342,
            top: -171,
        },
        "@media (max-width: 800px)": {
            height: 150,
            top: -75,
        },
    },
    triangleBottom: {
        position: "absolute",
        bottom: -281,
        left: 0,
        transform: "rotate(180deg)",
        zIndex: 10,
        "@media (max-width: 1660px)": {
            height: 342,
            bottom: -171,
        },
        "@media (max-width: 800px)": {
            height: 150,
            bottom: -75,
        },
    },
    dots: {
        position: "absolute",
        top: -38,
        left: 0,
        zIndex: 10,
        "@media (max-width: 1660px)": {
            height: 46,
            top: -23, 
        },      
        "@media (max-width: 800px)": {
            height: 34,
            top: -17,
            left: "-60%"
        },  
    },        
};
/*
const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 3,
    centerMode: false,
    autoplay: false,
    autoplaySpeed: 10000,
    nextArrow: <FaChevronRight />,
    prevArrow: <FaChevronLeft />,
    responsive: [{
        breakpoint: 768,
        settings: {
            slidesToShow: 1,
        },
    }]
};
*/
