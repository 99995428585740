import React from "react";
import { Container, Box, Text, Image, Flex } from "theme-ui";
import IsVisible from 'react-is-visible'
import Marquee from "react-fast-marquee";
import { useIntl } from "gatsby-plugin-intl"
import data from "../../cms/content/theGame.json";
import ballW from "assets/theGame/ball-white.svg";
import ballB from "assets/theGame/ball.svg";
import logo from "assets/imageBanner/wsl-logo.svg";
import En1 from "assets/theGame/game-image-1-eng.png";
import En2 from "assets/theGame/game-image-2-eng.png";
import En3 from "assets/theGame/game-image-3-eng.png";
import En4 from "assets/theGame/game-image-4.png";
import It1 from "assets/theGame/game-image-1-ita.png";
import It2 from "assets/theGame/game-image-2-ita.png";
import It3 from "assets/theGame/game-image-3-ita.png";
import It4 from "assets/theGame/game-image-4.png";
import strips from "assets/theGame/bg-strips.png";
import bg1 from "assets/theGame/bg-1.png";
import bg2 from "assets/theGame/bg-2.png";
import bg3 from "assets/theGame/bg-3.png";
import bg4 from "assets/theGame/bg-4.png";

export default function TheGame() {
    const intl = useIntl();

    function getTextStyle(index) {
        const colors = ["#D4AF37", "#FF0089", "#59B359", "#FF6B00"];
        return {
            ...styles.subTitle,
            color: colors[index % colors.length]
        }
    }

    const bgs = [bg1, bg2, bg3, bg4];

    const images = {
        En1, En2, En3, En4, It1, It2, It3, It4
    }

    function getCssClass(baseName, index) {
        let css = styles[baseName].base;
        if(index % 2) {
            css = {...css, ...styles[baseName].alt}
        }
        if(styles[baseName]["el" + index]) {
            css = {...css, ...styles[baseName]["el" + index]}
        }
        return css;
    }

    return (
        <Box as="section" id="theGame" sx={{overflow: "hidden"}}>
            <Box as="div" style={styles.rowYellowCnt}>
                <Marquee gradient={false}>
                    <Box as="div" sx={styles.rowYellow}>
                        <Box as="span" sx={styles.rowYellowText}>
                            WORLD SUPER LEAGUE
                        </Box>                   
                        <Image
                            sx={styles.ballIcon}
                            src={ballB}
                            alt="WSL ball"
                        />
                        <Box as="span" sx={styles.rowYellowText}>
                            WORLD SUPER LEAGUE
                        </Box>
                        <Image
                            sx={styles.logoIcon}
                            src={logo}
                            alt="WSL logo"
                        />
                        <Box as="span" sx={styles.rowYellowText}>
                            WORLD SUPER LEAGUE
                        </Box>
                        <Image
                            sx={styles.ballIcon}
                            src={ballB}
                            alt="WSL ball"
                        />
                        <Box as="span" sx={styles.rowYellowText}>
                            WORLD SUPER LEAGUE
                        </Box>
                        <Image
                            sx={styles.logoIcon}
                            src={logo}
                            alt="WSL logo"
                        />
                        <Box as="span" sx={styles.rowYellowText}>
                            WORLD SUPER LEAGUE
                        </Box>
                        <Image
                            sx={styles.ballIcon}
                            src={ballB}
                            alt="WSL ball"
                        />
                        <Box as="span" sx={styles.rowYellowText}>
                            WORLD SUPER LEAGUE
                        </Box><Image
                            sx={styles.logoIcon}
                            src={logo}
                            alt="WSL logo"
                        />
                        <Box as="span" sx={styles.rowYellowText}>
                            WORLD SUPER LEAGUE
                        </Box>
                        <Image
                            sx={styles.ballIcon}
                            src={ballB}
                            alt="WSL ball"
                        />
                        <Box as="span" sx={styles.rowYellowText}>
                            WORLD SUPER LEAGUE
                        </Box><Image
                            sx={styles.logoIcon}
                            src={logo}
                            alt="WSL logo"
                        />
                        <Box as="span" sx={styles.rowYellowText}>
                            WORLD SUPER LEAGUE
                        </Box>
                        <Image
                            sx={styles.ballIcon}
                            src={ballB}
                            alt="WSL ball"
                        />
                        <Box as="span" sx={styles.rowYellowText}>
                            WORLD SUPER LEAGUE
                        </Box>
                    </Box>
                </Marquee>
            </Box>
            {data.map((item, index) => (
                <Box as="div" sx={styles.row} key={index}>
                    <Image src={strips} sx={getCssClass("strips", index)} alt="background" />
                    <Container sx={styles.container}>                        
                        <Flex sx={getCssClass("band", index)} key={index}>
                            <Box as="div" sx={getCssClass("wrapperText", index)}>
                                <Box as="div" sx={styles.containerText}>
                                    <Text as="h3" sx={getTextStyle(index)}>
                                        {item[intl.locale].subTitle}
                                    </Text>
                                    <Text as="h2" sx={styles.title}>
                                        {item[intl.locale].title}
                                    </Text>
                                    <Text as="div" 
                                        sx={styles.body}
                                        dangerouslySetInnerHTML={{ __html: item[intl.locale].body}}
                                    >
                                        
                                    </Text>
                                </Box>
                            </Box>
                            <Box as="div" sx={styles.wrapperImage}>
                                <IsVisible once>
                                {(isVisible) => <Image className={isVisible ? 'enter-slideup in-view' : 'enter-slideup'} src={images[item[intl.locale].image]} alt={item.name} />}
                                </IsVisible>
                            </Box>
                        </Flex>                        
                    </Container>
                    <Image src={bgs[index]} sx={getCssClass("stain", index)} alt="background" />
                </Box>
            ))}
            <Box as="div" style={{overflow: "hidden"}}>                
                    <Box as="div" sx={styles.rowPink}> 
                    <Marquee gradient={false}>
                    <Box as="span" sx={styles.rowPinkText}>
                            WORLD SUPER LEAGUE
                        </Box>                   
                        <Image
                            sx={styles.ballIcon}
                            src={ballW}
                            alt="WSL ball"
                        />
                        <Box as="span" sx={styles.rowPinkText}>
                            WORLD SUPER LEAGUE
                        </Box>
                        <Image
                            sx={styles.logoIcon}
                            src={logo}
                            alt="WSL logo"
                        />
                        <Box as="span" sx={styles.rowPinkText}>
                            WORLD SUPER LEAGUE
                        </Box>
                        <Image
                            sx={styles.ballIcon}
                            src={ballW}
                            alt="WSL ball"
                        />
                        <Box as="span" sx={styles.rowPinkText}>
                            WORLD SUPER LEAGUE
                        </Box>
                        <Image
                            sx={styles.logoIcon}
                            src={logo}
                            alt="WSL logo"
                        />
                        <Box as="span" sx={styles.rowPinkText}>
                            WORLD SUPER LEAGUE
                        </Box>
                        <Image
                            sx={styles.ballIcon}
                            src={ballW}
                            alt="WSL ball"
                        />
                        <Box as="span" sx={styles.rowPinkText}>
                            WORLD SUPER LEAGUE
                        </Box><Image
                            sx={styles.logoIcon}
                            src={logo}
                            alt="WSL logo"
                        />
                        <Box as="span" sx={styles.rowPinkText}>
                            WORLD SUPER LEAGUE
                        </Box>
                        <Image
                            sx={styles.ballIcon}
                            src={ballW}
                            alt="WSL ball"
                        />
                        <Box as="span" sx={styles.rowPinkText}>
                            WORLD SUPER LEAGUE
                        </Box><Image
                            sx={styles.logoIcon}
                            src={logo}
                            alt="WSL logo"
                        />
                        <Box as="span" sx={styles.rowPinkText}>
                            WORLD SUPER LEAGUE
                        </Box>
                        <Image
                            sx={styles.ballIcon}
                            src={ballW}
                            alt="WSL ball"
                        />
                        <Box as="span" sx={styles.rowPinkText}>
                            WORLD SUPER LEAGUE
                        </Box>
                        <Image
                            sx={styles.ballIcon}
                            src={ballW}
                            alt="WSL ball"
                        />
                        <Box as="span" sx={styles.rowPinkText}>
                            WORLD SUPER LEAGUE
                        </Box>
                        <Image
                            sx={styles.ballIcon}
                            src={ballW}
                            alt="WSL ball"
                        />
                        <Box as="span" sx={styles.rowPinkText}>
                            WORLD SUPER LEAGUE
                        </Box>
                        <Image
                            sx={styles.ballIcon}
                            src={ballW}
                            alt="WSL ball"
                        />
                        <Box as="span" sx={styles.rowPinkText}>
                            WORLD SUPER LEAGUE
                        </Box>
                        <Image
                            sx={styles.ballIcon}
                            src={ballW}
                            alt="WSL ball"
                        />
                        <Box as="span" sx={styles.rowPinkText}>
                            WORLD SUPER LEAGUE
                        </Box>
                        </Marquee>
                    </Box>                
            </Box>
        </Box>
    );
}

const styles = {
    row: {
        position: "relative"
    },
    strips: {
        base: {
            position: "absolute",
            top: [0, 0, "49px"],
            left: 0,
            userSelect: "none",
            pointerEvents: "none",
            width: ["12px", "12px", "auto"]
        },
        alt: {
            right: ["auto", "auto", 0],
            left: "auto"
        },
    },
    stain: {
        base: {
            position: "absolute",
            bottom: 0,
            width: ["200px", "200px", "auto"],   
            right: 0,
            userSelect: "none",
            pointerEvents: "none",
            zIndex: 0,
        },
        alt: {
            left: ["auto", "auto", 0],
            right: [0, 0, "auto"],
        },
        el1: {
            transform: ["scaleX(-1) scaleY(-1)", "none", null]
        }
    },
    rowYellowCnt: {
        overflow: "hidden",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
        position: "relative",
        zIndex: 100
    },
    rowYellow: {
        height: ["40px", "40px", "50px", "60px"],
        backgroundColor: "#FFE162",
        display: "flex",
        justifyContent: "center",
        whiteSpace: "nowrap"
    },
    rowYellowText: {
        mx: ["9px", "9px", "14px", "14px"],
        fontFamily: "Lexend Zetta",
        fontStyle: "normal",
        fontWeight: "800",
        fontSize: ["8px", "8px", "12px", "16px"],
        letterSpacing: "-3px",
        color: "#000",
        alignSelf: "center",
        flexShrink: 0
    },
    containerText: {
        width: ["100%", "100%"],
    },
    ballIcon: {
        width: ["16px", "16px", "19px", "29px"],
        flexShrink: 0
    },
    logoIcon: {
        width: ["24px", "24px", "31px", "43px"],
        flexShrink: 0
    },
    band: {         
        alt: {
            flexDirection: ["column", "column", "row-reverse", "row-reverse"],
        },
        base: {                        
            flexDirection: ["column", "column", "row", "row"],
            mb: [null, null, "60px", "70px"],    
            position: "relative",
            zIndex: 10              
        }
    },
    rowPink: {
        mt: ["21px", "21px", "24px", "62px"],
        width: ["340%", "340%", "203%", "180%"],
        ml: ["-112%", "-53%", "-49%"],
        height: ["40px", "40px", "50px", "60px"],
        backgroundColor: "#FF0089",
        display: "flex",
        justifyContent: "center",
    },
    rowPinkText: {
        px: ["9px", "9px", "14px", "14px"],
        color: ["#000", "#000", "#fff"],
        fontFamily: "Lexend Zetta",
        fontStyle: "normal",
        fontWeight: "800",
        fontSize: ["8px", "8px", "12px", "16px"],
        lineHeight: "88px",
        letterSpacing: "-3px",
        alignSelf: "center",
    },
    subTitle: {
        textAlign: "left",
        fontFamily: "Barlow Condensed",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: ["16px", "16px", "20px", "30px", ],
        lineHeight: ["19px", "19px", "24px", "36px" ],
        letterSpacing: "0.15px",
        display: "block",
        mb: [null, null, "11px", "11px"],      
    },
    title: {
        ml: [null, null, null, "-10px"],
        textAlign: "left",
        color: "#000",
        fontFamily: "Lexend Zetta",
        fontStyle: "normal",
        fontWeight: "800",
        fontSize: ["30px", "30px", "40px", "60px", ],
        lineHeight: ["32px", "32px", "39px", "60px"],
        letterSpacing: ["-4px", "-4.85px", "-7.85px"],
        mb: ["13px", "13px", "15px", "15px"],  
    },
    body: {
        mb: ["21px", "21px", "0px", "0px"],
        textAlign: "left",
        color: "#333",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: ["12px", "12px", "14px", "18px"],
        lineHeight: ["18px", "18px", "21px", "32px"],
        letterSpacing: "1px",
    },
    wrapperImage: {
        mb: ["36px", "36px", "0px", "0px"], 
        width: [null, null, "100%", "50%"],
        px: ["32px", "32px", 0],
        alignSelf: "center",
    },
    wrapperText: {
        base: {
            width: [null, null, "100%", "50%"],
            display: "flex",
            justifyContent: "center",
            alignSelf: "center",
            paddingRight: ["32px", "32px", "60px"],
            paddingLeft: ["32px", "32px", 0]
        },
        alt: {
            paddingRight: ["32px", "32px", 0],
            paddingLeft: ["32px", "32px", "60px"]
        }
    },
    container: { 
        mt: ["39px", "39px", "0", "0"],
        display: "flex", 
        justifyContent: "center",
        flexWrap: "wrap", 
        pt: [null, null, "64px", "89px"],
        backgroundSize: "cover",
        textAlign: "center",    
        position: "relative",
        zIndex: 10,
        overflow: "visible"
    },
};
