import React from "react";
import { Box } from "theme-ui";
import { StickyProvider } from "contexts/app/app.provider";
import Seo from "components/seo";
import Layout from "components/layout";
import ImageBanner from "sections/imageBanner/imageBanner";
import TheGame from "sections/theGame/theGame";
import CardsGame from "../sections/cardsGame/cardsGame";
import HowToPlay from "../sections/howToPlay/howToPlay";
import GoodToken from "../sections/goodToken/goodToken";
import BuyPack from "../sections/buyPack/buyPack";
import RoadMap from "../sections/roadMap/roadMap";
// import Partners from "../sections/partners/partners";
import FooterImages from "../sections/footerImages/footerImages";
// import Footer from "../sections/footer/footer";
import "../styles/global.css"

export default function IndexPage() {
    return (
        <StickyProvider>            
            <Layout>                
                <Seo title="World Super League - NFT Card Game" />
                <Box as="div" style={{ backgroundColor: "#F5F5F5" }}>
                    <ImageBanner lang="en" />
                    <TheGame lang="en" />
                    <CardsGame />
                    <HowToPlay />
                    <GoodToken />
                    <BuyPack />
                    <RoadMap />
                    {/*<Partners />*/}
                    <FooterImages showCards={true} />
                    {/*<Footer />*/}
                </Box>
            </Layout>
        </StickyProvider>
    );
}