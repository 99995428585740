import React from "react";
import { Container, Box, Text, Image } from "theme-ui";
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"
import IsVisible from 'react-is-visible'
//import image from "assets/goodToken/good-token.png";
import { Button } from "../../components/button";
import farmingIt from "assets/goodToken/farming-it.jpg";
import farmingEn from "assets/goodToken/farming-en.jpg";
import rip from "assets/goodToken/rip.svg";
import ripPurple from "assets/goodToken/rip_purple.svg";
import logo from "assets/buyPack/logo.svg";
import dots2 from "assets/goodToken/dots.png";
export default function GoodToken() {
    const intl = useIntl();

    const img = {
        it: farmingIt, en: farmingEn
    }

    return (
        <Box as="section" id="goodToken" sx={styles.banner}>            
            <Image src={rip} alt="g" sx={styles.rip} />
            <Image src={dots2} alt="g" sx={styles.dots2} />
            <Container sx={styles.container}>
                <Box as="div" sx={styles.wrapperText}>
                    <Box as="div" sx={styles.textContainer}>
                        <Text as="h3" sx={styles.subTitle}>
                            <FormattedMessage id="farming.subtitle" />
                        </Text>
                        <Text as="h2" sx={styles.title}>
                            FARMING
                        </Text>
                        <Text as="div" sx={styles.body}>
                        <FormattedMessage id="farming.text" />
                        </Text>
                        <Button 
                            label={intl.formatMessage({ id: "startFarming" })} 
                            target="_blank" 
                            to="https://wallet.noku.io/app/farming" 
                            variant="lg"
                            color="dark"
                            />
                    </Box>
                </Box>
                <Box as="div" sx={styles.wrapperImage}>
                    {/*<Image src={image} alt="good token image" sx={styles.image}/>*/}
                    <IsVisible once>
                    {(isVisible) => <Image src={img[intl.locale]} className={isVisible ? 'enter-slideup in-view' : 'enter-slideup'} alt="Farming" sx={styles.farming}/>}
                    </IsVisible>
                </Box>
            </Container>
            <Image src={ripPurple} alt="g" sx={styles.ripPurple} />
            <Image src={logo} alt="g" sx={styles.logo} />
        </Box>
    );
}

const styles = {
    banner: {
        display: [null, null, "flex"], 
        justifyContent: [null, null, "end"],
        position: "relative",
        py: [50, 100, 100, 200, 400],
        overflow: "hidden"
    },
    image: {
        display: "block",
        position: "absolute",
        bottom: ["-0%", "-10%", "-10%"],
        width: ["100%", "70%"],
        right: "-20%",
        zIndex: 10
    },
    dots2: {
        position: "absolute",
        bottom: -220,
        right: 0,
        zIndex: 10,
        maxWidth: "50%"
    },
    container: {
        display: "flex",
        py: [40, 80, 160],
        px: [32],
        justifyContent: "center",
        alignItems: "center",
        flexDirection: ["column", null, "row"]
    },
    wrapperText: {
        width: ["100%", null, "33%"],
        display: "flex",
        justifyContent: "center",
        alignSelf: "center",
        mb: [30, 0],
    },
    wrapperImage: {
        width: ["100%", null, "66%"],
    },
    button: {
        display: "flex",
        justifyContent: "center",
        border: ["1px solid #666", "2px solid #666", "2px solid #666"],
        borderRadius: "3px",
        width: ["90px", "110px", "170px"],
        height: ["30px", "30px", "50px"],
        alignItems: "center",
        cursor: "pointer",
        textDecoration: "none",
    },
    textButton: {
        color: "#666",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: ["12px", "12px", "18px"],
        lineHeight: "22px",
        textAlign: "center",
        letterSpacing: "0.15px",
    },
    body: {
        mb: ["20px", "20px", "37px"],
        color: "#333",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: ["12px", "14px", "18px"],
        lineHeight: ["18px", "21px", "32px"],
        letterSpacing: "1px",
    },
    subTitle: {
        color: "#FF0089",
        fontFamily: "Barlow Condensed",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: ["16px", "20px", "30px"],
        lineHeight: ["19px", "24px", "36px"],
        letterSpacing: "0.15px",
    },
    title: {
        mt: ["2px", "5px", "11px"],
        mb: ["13px", "19px", "21px"],
        ml: ["-5px", "-8px"],
        color: "#000",
        fontFamily: "Lexend Zetta",
        fontStyle: "normal",
        fontWeight: "800",
        fontSize: ["30px", "40px", "60px"],
        lineHeight: ["32px", "39px", "60px"],
        letterSpacing: ["-4px", "-6.85px", "-10.85px"],
    },    
    rip: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        right: 0,
        zIndex: 1
    },
    ripPurple: {
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "100%",
        right: 0,
        zIndex: 10
    },
    logo: {
        position: "absolute",
        bottom: 0,
        left: "-5%",
        width: "60%",
        right: 0,
        zIndex: 1,
    }
};